import { FetchAllPieceContentsRequest, PieceContent, UpdatePieceContentRequest, UpdatePieceContentAudioRequest, FetchPieceContentAudioRequest } from "./piece-content.models";

export class ResetPieceContents {
  static readonly type = '[Pieces] Reset Content';
}

export class ResetPieceContentsDraft {
  static readonly type = '[Pieces] Reset Content Draft';
}

export class FetchAllPieceContents {
  static readonly type = '[Pieces] Fetch All Contents';
  constructor(public payload: FetchAllPieceContentsRequest) { }
}

export class FetchPieceContent {
  static readonly type = '[Pieces] Fetch Content';
  constructor(public payload: number) { }
}

export class CreatePieceContent {
  static readonly type = '[Pieces] Create Content';
  constructor(public payload: PieceContent) { }
}

export class CreatePieceContentDraft {
  static readonly type = '[Pieces] Create Content Draft';
  constructor(public payload: string) { }
}

export class UpdatePieceContent {
  static readonly type = '[Pieces] Update Content';
  constructor(public payload: UpdatePieceContentRequest) { }
}

export class DeletePieceContent {
  static readonly type = '[Pieces] Delete Content';
  constructor(public payload: number) { }
}

export class DeletePieceContentDraft {
  static readonly type = '[Pieces] Delete Content Draft';
  constructor(public payload: string) { }
}

export class FetchPieceContentAudio {
  static readonly type = '[Pieces] Fetch Content Audio';
  constructor(public payload: FetchPieceContentAudioRequest) { }
}

export class UpdatePieceContentAudio {
  static readonly type = '[Pieces] Update Content Audio';
  constructor(public payload: UpdatePieceContentAudioRequest) { }
}

export class FetchTextToSpeechLanguages {
  static readonly type = '[Pieces] Fetch Text To Speech Languages';
}
